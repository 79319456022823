import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { Session } from "./session.server";

export const SessionContext = createContext<Session | null>(null);

/**
 * Provides a new session context.
 * @example
 * let session = useSession();
 */
export function SessionProvider({ session, children }: { session: Session; children: ReactNode }) {
  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}

/**
 * Get the session.
 * @example
 * let session = useSession();
 */
export function useSession() {
  return useContext(SessionContext);
}

/**
 * Get the CSRF token, this should be used to send it in a submit.
 * @example
 * let token = useAuthenticityToken();
 * let submit = useSubmit();
 * function sendFormWithCode() {
 *   submit(
 *     { csrf: token, ...otherData },
 *     { action: "/action", method: "post" },
 *   );
 * }
 */
export function useAuthenticityToken() {
  const session = useSession();
  if (!session) throw new Error("Missing SessionProvider.");
  if (!session.authenticityToken) throw new Error("Missing authenticity token.");
  return session.authenticityToken;
}

/**
 * Render a hidden input with the name csrf and the CSRF token as value.
 * ```tsx
 * return (
 *   <Form action="/login" method="post">
 *     <AuthenticityTokenInput />
 *     <input name="email" type="email" />
 *     <input name="password" type="password" />
 *     <button type="submit">Login</button>
 *   </Form>
 * );
 * ```
 */
export function AuthenticityTokenInput() {
  const token = useAuthenticityToken();
  return <input type="hidden" className="hidden" value={token} name="csrf" />;
}
